@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


* {
  transition-property: all;
  transition-duration: 0.1s;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* scrollbar-color: rgb(134 239 172) white; */
  /* scrollbar-width: thin; */
}
html,
body {
  padding: 0;
  margin: 0;
  /* overflow-x: hidden; */
}

a {
  color: inherit;
  text-decoration: none;
}
.formcontainer {
  @apply flex flex-col my-12
}
.formTitle {
  @apply font-semibold mb-4
}
.heading{
  @apply font-extrabold sm:text-5xl  text-2xl text-black
}
::-webkit-scrollbar{
  /* height: 4px;
  width: 4px;
  @apply bg-white */
}

::-webkit-scrollbar-thumb{
  @apply bg-green-300 rounded-2xl
}

@page{
  size: letter;
}

@keyframes error {
  0% {
    border-color: red;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: red;
  }
}
.ReactCrop__image {
  width: 100% !important;
}